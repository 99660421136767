#footer {
    .container {
        &.contato {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 130px;
            background: var(--ion-color-primary);
            border-radius: 10px;
            padding: 0 95px;
            margin-top: 75px;
            margin-bottom: -75px;
            position: relative;
            z-index: 2;

            @media (max-width: 991px) {
                padding: 10px;
                width: calc(100vw - 2rem);
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            > img {
                margin-top: -50px;
                width: 160px;
                height: 180px;
                @media (max-width: 991px) {
                    display: none;
                }
            }

            .textos {
                @media (max-width: 991px) {
                    text-align: center;
                }

                h3 {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 36px;
                    line-height: 85%;
                    letter-spacing: -0.025em;
                    color: var(--ion-color-primary-contrast);
                    margin-bottom: 10px;
                    @media (max-width: 991px) {
                        font-size: 24px;
                    }
                }

                p {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: -0.025em;
                    color: var(--ion-text-light);
                    @media (max-width: 991px) {
                        display: none;
                    }
                }
            }

            a {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 100%;
                letter-spacing: -0.025em;
                color: var(--ion-color-secondary-contrast);
                padding: 12px 24px;
                background-color: var(--ion-color-secondary);
                border-radius: 6px;
                transition: 0.3s;
                &:hover {
                    background: var(--ion-color-secondary-shade);
                }
            }
        }
    }

    .footer {
        background-color: #f5f5f5;
        padding-top: 125px;
        padding-bottom: 70px;
        .container {
            display: grid;
            grid-template-columns: 1fr 1fr 1.5fr 1fr;
            gap: 45px;

            @media (max-width: 991px) {
                grid-template-columns: 1fr;
            }

            div {
                .logos {
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    img:first-child {
                        width: 140px;
                        height: 35px;
                    }

                    img:last-child {
                        width: 109px;
                        height: 35px;
                    }

                    + p {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 140%;
                        letter-spacing: -0.025em;
                        margin: 25px 0;
                    }
                }

                .socials {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    a {
                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }

                h3 {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 100%;
                    letter-spacing: -0.025em;
                    color: var(--ion-color-primary);
                    margin-bottom: 30px;
                    margin-top: 15px;
                }

                h2 {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 100%;
                    letter-spacing: -0.025em;
                    color: var(--ion-color-primary);
                    margin-bottom: 10px;
                }

                .links {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    a {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 100%;
                        letter-spacing: -0.025em;
                        color: var(--ion-text-color);
                    }
                }

                > .cotacao {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 100%;
                    letter-spacing: -0.025em;
                    color: var(--ion-color-primary-contrast);
                    background: var(--ion-color-primary);
                    padding: 10px 30px;
                    border-radius: 6px;
                    margin-top: 35px;
                    display: block;
                    width: fit-content;
                    transition: 0.3s;
                    &:hover {
                        background: var(--ion-color-primary-shade);
                    }
                }
            }
        }
    }

    > p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        text-align: center;
        letter-spacing: -0.025em;
        color: var(--ion-text-light);
        background-color: #f5f5f5;
        padding: 25px 0;
        margin: 0;
        a {
            color: var(--ion-text-light);
        }
    }
}
