::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: var(--ion-color-secondary);
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--ion-color-secondary-shade);
}
::-webkit-scrollbar-thumb:active {
  background: var(--ion-color-primary);
}
::-webkit-scrollbar-track {
  background: #eaeaea;
}
::-webkit-scrollbar-track:hover {
  background: #eaeaea;
}
::-webkit-scrollbar-track:active {
  background: #eaeaea;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
.App {
  overflow: hidden;
}
.schema {
  display: none !important;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  letter-spacing: -0.025em;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
img {
  display: block;
}
body {
  line-height: 1;
  background-color: var(--ion-background-color);
}
a {
  text-decoration: none;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
b,
strong {
  font-weight: 600;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.clear {
  clear: both;
}

body {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  letter-spacing: -0.025em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--ion-text-color);
}

button,
input,
textarea {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  letter-spacing: -0.025em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  outline: none !important;
  box-sizing: border-box;
}

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3B3E99;
  --ion-color-primary-rgb: 59, 62, 153;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-shade: #23267c;
  --ion-color-primary-tint: #6064ce;

  /** secondary **/
  --ion-color-secondary: #FF2E34;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-shade: #e01a21;
  --ion-color-secondary-tint: #ff565b;

  /** tertiary **/
  --ion-color-tertiary: #404755;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-shade: #2a313f;
  --ion-color-tertiary-tint: #555a64;

  --ion-background-color: #FFFFFF;

  --ion-text-color: #404755;

  --ion-text-light: #B8B8B8;

}

button {
  &:disabled {
    opacity: 0.5 !important;
  }
}

.hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 10px;
  width: 10px;
  overflow: hidden;
}

.inactive {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

#modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.3);
  transition: 0.5s;
  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .content {
    width: 500px;
    max-width: 90%;
    background-color: white;
    padding: 50px;
    border-radius: 5px;
    text-align: center;
    h3 {
      font-size: 32px;
      font-weight: bold;
      color: var(--ion-text-color);
      margin-bottom: 15px;
    }

    p {
      font-size: 16px;
      color: var(--ion-text-color);
      font-weight: 500;
      margin-bottom: 50px;
    }

    button {
      width: 100%;
      height: 44px;
      background: var(--ion-color-primary);
      border-radius: 10px;
      display: block;
      margin: 0 auto;
      color: white;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.container {
  display: block;
  width: 100%;
  max-width: 1250px;
  padding: 0 1rem;
  margin: 0 auto;
}

p {
  &.title {
    font-weight: 800;
    font-size: 36px;
    line-height: 100%;
    color: var(--ion-color-primary);
    margin-bottom: 16px;
  }
  color: var(--ion-text-color);
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 15px;
}

form {
  .check {
    margin-top: 15px;
    position: relative;
    input {
      position: absolute;
      left: 7px;
      top: 7px;
      width: 1px;
      height: 1px;
      opacity: 0.1;
      pointer-events: none;

      + label {
        cursor: pointer;
        text-align: left;
        display: grid;
        grid-template-columns: 25px 1fr;
        gap: 5px;
        text-align: justify;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.025em;
        align-items: center;
        color: var(--ion-text-color);
        span.checkbox {
          width: 22px;
          height: 22px;
          border: 1px solid #d1d1d1;
          background-color: white;
          border-radius: 6px;
          position: relative;

          &::before {
            content: "";
            width: 14px;
            height: 14px;
            background-color: var(--ion-color-tertiary);
            position: absolute;
            top: 3px;
            left: 3px;
            border-radius: 3px;
            transition: 0.3s;
            opacity: 0;
          }
        }

        span {
          a {
            color: var(--ion-text-color);;
            font-weight: 700;
            transition: .3s;
            &:hover {
              color: var(--ion-color-tertiary-tint);
            }
          }
        }
      }

      &:checked {
        + label {
          span {
            &::before {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
.form-group {
  position: relative;
  margin-top: 20px;
  textarea {
    padding: 15px;
  }
  input,
  textarea {
    background: #ffffff;
    border: 1px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 6px;
    height: 48px;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: var(--ion-text-color);
    letter-spacing: -0.025em;
    padding-left: 15px;
    + label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: -0.025em;
      color: #8C929D;
      opacity: 1;
      position: absolute;
      left: 15px;
      top: 17px;
      transition: 0.3s;
      pointer-events: none;
    }

    &:valid,
    &:focus,
    &:read-only {
      + label {
        top: -15px;
        left: 0;
        font-size: 12px;
        opacity: 1;
        text-transform: lowercase;
        color: #8C929D;
      }
    }
  }

  textarea {
    height: 70px;
    resize: none;
  }
}

.heading-title {
  font-size: 42px;
  color: var(--ion-color-primary);
}

ul.bread {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: var(--ion-color-primary);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 90px;
  gap: 40px;

  li {
    position: relative;
    &::before {
      content: ">";
      position: relative;
      left: -20px;
    }

    &:first-child {
      &::before {
        display: none;
      }
    }
    a {
      color: var(--ion-color-primary);
    }
  }
}

.products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  padding: 40px 0 80px;
  .product {
    padding: 20px;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.3s;
    box-shadow: 0px 4px 25px -5px rgba(0, 0, 0, 0.1);
    &:hover {
      .image {
        transform: scale(1.05);
      }
    }
    .image {
      width: 100%;
      height: 320px;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      transform-origin: bottom;
      transition: 0.2s;
    }

    .text {
      .title {
        font-style: normal;
        font-weight: 300;
        font-size: 14px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin-bottom: 10px !important;
        margin-top: 0 !important;
        color: var(--ion-text-color) !important;
      }

      .price {
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
      }

      a {
        width: 100%;
        height: 48px;
        background-color: var(--ion-color-primary);
        display: flex;
        color: white;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        border-radius: 5px;
        margin-top: 15px;
        transition: 0.2s;

        &:hover {
          background-color: var(--ion-color-primary-shade);
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .container {
    max-width: unset;
  }

  .products {
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;

    .product {
      padding: 5px;
      box-shadow: 0 0 0;
      .image {
        height: 40vw;
      }
      .text {
        a {
          font-size: 12px;
        }
      }
    }
  }

  ul.bread {
    display: none;
  }

  .heading-title {
    font-size: 28px;
    line-height: 36px;
  }
}

.fade-appear,
.fade-enter {
  opacity: 0;
}

.fade-appear-active,
.fade-enter-active {
  transition: opacity 0.3s linear;
  opacity: 1;
}

.fade-exit {
  transition: opacity 0.2s linear;
  opacity: 1;
  position: fixed;
  top: 100px;
  left: -3px;
  width: 100vw;
}

.fade-exit-active {
  opacity: 0;
}

.suspense {
  width: 100vw;
  height: 100vh;
}