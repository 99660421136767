#header {
    width: 100vw;
    height: 100px;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
        gap: 15px;
        > a {
            img {
                width: 280px;
                height: 70px;
            }
        }

        > button {
            display: none;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 9991;
            width: 35px;
            height: 40px;
            background-color: var(--ion-color-primary);
            border: 0;
            transition: 0.3s;
            @media (max-width: 991px) {
                display: flex;
            }

            img {
                width: 24px;
                height: 24px;
            }

            &.open {
                height: 35px;
                border-radius: 50%;
                background: var(--ion-color-secondary);
                img {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .menu {
            display: flex;
            align-items: center;
            justify-content: space-around;
            a {
                height: 100px;
                padding: 0 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                letter-spacing: -0.025em;
                color: var(--ion-text-color);
                border-bottom: 2px solid transparent;
                transition: 0.3s;
                &:hover {
                    border-color: var(--ion-color-secondary);
                }

                &:last-child {
                    background: #2eb134;
                    border-radius: 6px;
                    color: var(--ion-color-secondary-contrast);
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 100%;
                    letter-spacing: -0.025em;
                    padding: 10px 14px;
                    transition: 0.3s;
                    margin-top: 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 15px;
                    width: fit-content;
                    height: fit-content;
                    text-transform: uppercase;
                    &:hover {
                        filter: brightness(0.8);
                        border-bottom: 2px solid transparent;
                    }

                    @media (max-width: 991px) {
                        margin: 0 auto;
                    }
                }
            }

            div {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 100%;
                letter-spacing: -0.025em;
                color: #ffffff;
                background-color: var(--ion-color-primary);
                padding: 14px;
                border-radius: 6px;
            }

            @media (max-width: 991px) {
                position: fixed;
                top: 0;
                right: -320px;
                width: 320px;
                flex-direction: column;
                height: 100vh;
                padding: 20vh 0;
                background-color: var(--ion-color-primary);
                transition: 0.3s;
                z-index: 9990;
                a {
                    color: var(--ion-color-primary-contrast);
                }
                div {
                    background-color: var(--ion-color-secondary);
                }
                &.open {
                    right: 0;
                }
            }
        }
    }
}
